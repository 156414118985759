<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper container-xxl p-0">
        <banner
          :title="`${category}`"
          :breadcrumb="[{ label: 'Category ' }, { label: `${serviceName}` }]"
        >
        </banner>
        <div class="row" style="margin-top: -45px">
          <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-12 col-md-12 col-lg-12 col-12 text-start">
                    <h4>
                      <strong style="color: #00364f">Step-{{stepNo}} : Client And Bussiness Information</strong>
                    </h4>
                  </div>
                  <!-- <div class="col-xl-6 col-md-6 col-lg-6 col-12 col-12 text-end"></div> -->
                </div>
                <!-- <div class="progress">
                  <div
                    style="background-color: #f21300; width: 20%"
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="40"
                    aria-valuemin="40"
                    aria-valuemax="100"
                  >
                    20%
                  </div>
                </div> -->

                <form @submit.prevent="itrfillingservice">
                  <div class="row mb-2">
                    <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Pan <small style="color:#f21000">*</small></label>
                      <input
                       v-if="formPanDisabled == 'false'"
                        required
                        type="text"
                        @change="findRecordeByPanId1()"
                        id="pan"
                        class="form-control"
                        placeholder="Pan"
                        style="height: 33px; text-transform: uppercase"
                        name="panNo"
                        v-model="panNo"
                      />


                      <input
                      v-else
                      disabled
                        required
                        type="text"
                        @change="findRecordeByPanId1()"
                        id="pan"
                        class="form-control"
                        placeholder="Pan"
                        style="height: 33px; text-transform: uppercase"
                        name="panNo"
                        v-model="panNo"
                      />


                      <!-- <small style="color:red;" v-if="form.errors.error"> {{ form.errors.error.pan[0] }}</small> -->
                      <div>
                        <strong>
                          <small
                        style="color:#f21000"
                          @click="openModal"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#clientModal"
                          >create New Client</small
                        >
                        </strong>
                     
                      </div>
                    </div>
                    <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Business Name <small style="color:#f21000">*</small></label>
                     

                      <select style="height: 33px; text-transform: uppercase" required v-model="business_id" class="form-select" aria-label="Default select example">
  <option selected disabled>Select...</option>
  <option
                          :value="bus.id"
                          v-for="(bus, index) in allBusiness"
                          :key="index"
                        >
                          <span v-if="bus">{{ bus.name }}</span>
                        </option>
</select>
                      <!-- <select
                        required
                        style="height: 33px; text-transform: uppercase"
                        class="form-select"
                        aria-label="Default select example"
                v-model="business_id"
                      >        
                      
                        <option selected disabled>Business...</option>
                        <option
                          :value="bus.id"
                          v-for="(bus, index) in allBusiness"
                          :key="index"
                        >
                          <span v-if="bus">{{ bus.name }}</span>
                        </option>
                      </select> -->

                      <small style="color: red" v-if="form.errors"
                        ><span v-if="form.errors.error"
                          ><span v-if="form.errors.error.business_id">{{
                            form.errors.error.business_id[0]
                          }}</span></span
                        ></small
                      >
                      <!-- <small style="color:red;" v-if="form.errors.error"> {{form.errors.error.business_id[0]}}</small> -->
                <strong>
                  <small
                        @click="openBusinessModal"
                        type="button"
                        style="color:#f21000"
                        data-bs-toggle="modal"
                        data-bs-target="#createBussiness"
                        >Create New Bussiness</small
                      >
                </strong>
                     
                    </div>

                    <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>First Name </label>
                      <input
                      disabled
                        type="tel"
                        class="form-control"
                        v-model="form.fname"
                        placeholder="Name "
                        style="height: 33px; text-transform: uppercase"
                      />
                    </div>
                    <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Last Name </label>
                      <input
                      disabled
                        type="tel"
                        class="form-control"
                        v-model="form.lname"
                        placeholder="Last Name "
                        style="height: 33px; text-transform: uppercase"
                      />
                    </div>
                    <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Address </label>
                      <input
                      disabled
                        type="text"
                        class="form-control"
                        v-model="form.address"
                        placeholder=" Address"
                        style="height: 33px; text-transform: uppercase"
                      />
                    </div>

                    <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Phone </label>
                      <input
                      disabled
                        type="text"
                        v-model="form.phone"
                        maxlength="10"
                        class="form-control"
                        placeholder="Phone number "
                        style="height: 33px; text-transform: uppercase"
                      />
                    </div>
                    <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Alt Phone Number </label>
                      <input
                      disabled
                        v-model="form.alternativePhone"
                        type="text"
                        maxlength="6"
                        class="form-control"
                        placeholder="Alt Phone Number "
                        style="height: 33px; text-transform: uppercase"
                      />
                    </div>

                    <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Email </label>
                      <input
                      disabled
                        v-model="form.email"
                        type="email"
                        class="form-control"
                        placeholder="Email Address"
                        style="height: 33px"
                      />
                    </div>
                    <!-- <div
                      class="col-xl-12 col-md-12 col-lg-12 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Password </label>
                      <input
                        v-model="form.itrPassword"
                        type="text"
                        class="form-control"
                        placeholder="Password"
                        style="height: 33px"
                      />
                    </div> -->
                  </div>
                  <h4 class="text-start font text">
                   <strong style="color:#00364f">Charges</strong>  : <span> ₹ {{ serviceCharge }}</span>
                  </h4>
                  <div class="row">
                    
                    <div class="col ">
                      <!-- <button
                        @click="openBusinessModal"
                        type="button"
                        class="btn btn-sm bg-success text-white"
                        data-bs-toggle="modal"
                        data-bs-target="#createBussiness"
                      >
                        New Business
                      </button> -->
                      <!-- <router-link to="/retailer/udyam"> -->
                      <button
                        type="button"
                        @click="redirectBack"
                        class="btn btn-sm text-white"
                        style="background-color: #00364f; width: 70%"
                      >
                        <strong>BACK</strong>
                      </button>
                    <!-- </router-link> -->
                    </div>
                    <div class="col text-end">
                      <button
                        type="submit"
                        class="btn btn-sm text-white"
                        style="background-color: #00364f"
                      >
                        <strong>Confirm</strong>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px" v-if="faqsRecords.length != 0">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <h2 class="heading">FAQ's</h2>
                    <div
                      class="accordion accordion-margin accordion-border"
                      id="accordionMargin"
                    >
                      <div
                        class="accordion-item"
                        v-for="(faq, index) in faqsRecords"
                        :key="index"
                      >
                    
                        <h2 class="accordion-header" id="headingMarginOne">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="`#faq${index}`"
                            aria-expanded="false"
                            aria-controls="accordionMarginOne"
                          >
                            <i data-feather="plus" class="accordian-plus"></i>
                            {{ faq.title }}
                          </button>
                        </h2>
                        <div
                          :id="`faq${index}`"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingMarginOne"
                          data-bs-parent="#accordionMargin"
                        >
                          <div class="accordion-body">
                            <div class="row">
                            
                              <div class="col-12">{{ faq.description }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <h2 class="heading">Videos</h2>

                    <div
                      class="video-player"
                      id="plyr-video-player"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div>
                        <!-- <div
                          class="scrollmenu"
                          v-for="(faq, index) in faqsRecords"
                          :key="index"
                        >
                          <a href="#" style="width: 100%" v-html="faq.embedVideo"></a>
                        </div> -->

                        <div class="scrollmenu">
                          <a href="#home" v-for="(vid, index) in videos" :key="index"
                            ><div style="width: 100%" v-html="vid"></div
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title text h3-heading" id="staticBackdropLabel">
            <b>Add Client</b>
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="edit == 'false' ? addNewClient() : updateClient()"
            id="formId"
          >
            <div class="row">
              <div class="col-xl-7 col-lg-7 col-md-7 col-12">
                <div class="row">
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label> Client Pan</label>
                    <input
                      type="tel"
                      maxlength="10"
                      v-model="form.pan"
                      class="form-control"
                      placeholder=" Pan"
                      style="text-transform: uppercase"
                      disabled
                    />
                  </div>

                  <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                        <label>Enter Aadhar Number</label>
                                        <input type="tel" maxlength="12" v-model="form.aadharNo" class="form-control"
                                            placeholder="Mobile Number">
                                    </div> -->
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Mobile No.</label>
                    <input
                      type="tel"
                      maxlength="10"
                      v-model="form.phone"
                      class="form-control"
                      placeholder="Mobile Number"
                    />
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>First Name</label>
                    <input
                      type="text"
                      v-model="form.fname"
                      class="form-control"
                      placeholder="First name"
                      aria-label="First name"
                      style="text-transform: uppercase"
                    />
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Last Name</label>
                    <input
                      type="text"
                      v-model="form.lname"
                      class="form-control"
                      placeholder="Last name"
                      aria-label="Last name"
                      style="text-transform: uppercase"
                    />
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Date Of Birth</label>
                    <input
                      type="date"
                      v-model="form.dob"
                      class="form-control"
                      placeholder="DOB"
                      style="text-transform: uppercase"
                    />
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Gender</label>
                    <select
                      id="inputState"
                      v-model="form.gender"
                      class="form-select"
                      style="text-transform: uppercase"
                    >
                      <option>Male</option>
                      <option>FeMale</option>
                      <option>Other</option>
                    </select>
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Email</label>
                    <input
                      type="email"
                      class="form-control"
                      v-model="form.email"
                      placeholder="E-Mail"
                      style="text-transform: uppercase"
                    />
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Alternate Number</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="form.alternativePhone"
                      placeholder="Alternate Number"
                    />
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-5 col-12 border-3 border-start">
                <div class="row">
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Address line 1</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.address"
                      placeholder="Address Line-1"
                      style="text-transform: uppercase"
                    />
                  </div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Address line 2</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.address2"
                      placeholder="Address Line-2"
                      style="text-transform: uppercase"
                    />
                  </div>

                  <div
                    class="col-xl-4 col-lg-4 col-md-4 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>State</label>
                    <select id="inputState" v-model="form.state_id" class="form-select">
                      <option
                        v-for="(state, index) in states"
                        :key="index"
                        :value="state.id"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                  </div>

                  <div
                    class="col-xl-4 col-lg-4 col-md-4 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>City</label>
                    <input
                      type="text"
                      v-model="form.city"
                      class="form-control"
                      id="inputCity"
                      placeholder="City"
                      style="text-transform: uppercase"
                    />
                  </div>

                  <div
                    class="col-xl-4 col-lg-4 col-md-4 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Pin Code</label>
                    <input
                      type="number"
                      maxlength="6"
                      v-model="form.pin"
                      min="1"
                      class="form-control"
                      id="inputZip"
                      placeholder="Pin"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer" style="margin-top: 10px">
              
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <b> <font-awesome-icon icon="check" /> Confirm </b>
              </button>

              <button
                type="reset"
                class="btn btn-sm text-white"
                style="background-color: red"
              >
                <b>
                  <font-awesome-icon icon="remove" />
                </b>
                Reset
              </button>
              <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end of the model -->

  <ModalClient />

  <ModalBusiness />
  <!-- <DocumentUpload ref="DocumentUpload" /> -->
  <div v-if="loading == true">
          <Spinner />
        </div>

</template>

<script>
import ModalClient from "../../../components/retailer/comman/ClientModal.vue";
import ModalBusiness from "../../../components/retailer/comman/BusinessModal.vue";
import Banner from "../../../components/retailer/comman/Banner.vue";
import Spinner from "../../../components/retailer/comman/Spinner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";


// import Form from 'vform'
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "Udyam",
  components: {
    Banner,
    ModalClient,
    ModalBusiness,
    Spinner,
    // DocumentUpload
  },
  data() {
    return {
      stepNo:2,
      formPanDisabled : 'false',
      loading: false,
      faqVideosRecords: [],
      faqsRecords: [],
      category: "",
      serviceName: "",
      panNo1: null,
      clientservicedocument: [],
      allDoccs: [],
      uploadDocc_form: {
        client_service_id: null,
        service_document_id: null,
        alt: "",
        caption: "",
        serviceDocument: "",
        cwidth: "",
        cheight: "",
        cx: "",
        cy: "",
      },
      allBusiness: [],
      business_id: null,
      states: [],
      panNo: "",
      record: {},
      serviceCharge: "",
      charges: {},
      videos: [],

      formCharge:{
        serviceModulesIds: [],
        service_id: null,
        state_id : null,
        authrizationCapital:'',
      },
      form: {
        photo: "",
        pan: "",
        fname: "",
        lanme: "",
        phone: "",
        email: "",
        dob: null,
        gender: null,
        address: null,
        address2: null,
        alternativePhone: null,
        city: null,
        fatherName: "",
        pin: null,
        state_id: null,
        code: "",
        errors: {},
      },
      veryfied: "false",
      exist: "false",
    };
  },
  methods: {

    defaultApiChargeCalulate(){
      this.loading = true;
      this.$axios
        .post("retailer/service/charge", this.formCharge, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("----------------service chargs ----0");
          this.serviceCharge = parseInt(res.data.serviceCharge) + parseInt(res.data.serviceChargeGst);

          var proprietorship = {};
      proprietorship = JSON.parse(localStorage.getItem("service"));
      proprietorship.serviceCharge =this.serviceCharge;



                localStorage.setItem("service", JSON.stringify(proprietorship));
          // console.log(this.charges);
          // $("#exampleModalCenter").modal("show");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            text: error.response.data.error,
          });
        });
    },

    getVidoe(){
          for(let i  =  0; i < this.faqsRecords.length; i++ ){
            this.videos.push(this.faqsRecords[i].embedVideo)
          }
  
    },
    getFaqsRecords() {
      var data = JSON.parse(localStorage.getItem("service"));
      this.service_id = data.service_id;
      console.log(this.service_id);
      this.loading = true;
      this.$axios
        .get(`retailer/servicefaq?service_id=${this.service_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res.data;
          this.faqsRecords = res.data.data.data;
          this.getVidoe()
        })
        .finally(() => (this.loading = false));
    },
    findRecordeByPanId1() {
      // alert(this.panNo1)
      //    console.log($event.target.value)
      // this.panNo = $event.target.value;
      // console.log("----------------------------------------------------");
      console.log(this.panNo);

      this.findRecordeByPanId();
      // console.log("----------------------------------------------------");
    },
    findRecordeByPanId2($event) {
      this.business_id = $event.target.value;
      console.log("----------------------------------------------------");
      console.log(this.business_id);
      console.log("----------------------------------------------------");
    },
    itrfillingservice() {
      // alert(this.business_id);
      var proprietorship = {};
      proprietorship = JSON.parse(localStorage.getItem("service"));
      proprietorship.business_id = this.business_id;
      proprietorship.client_id = this.form.id;
      localStorage.setItem("service", JSON.stringify(proprietorship));
      this.$swal
        .fire({
          title: "Are You Sure ?",
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          // denyButtonText: `No`,


          cancelButtonColor: "#f21000",
    confirmButtonColor: "#00364f",
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .post("retailer/clientservice", proprietorship, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res.data.clientService.id;
                proprietorship = JSON.parse(localStorage.getItem("service"));
                proprietorship.client_service_id = res.data.clientService.id;
                localStorage.setItem("service", JSON.stringify(proprietorship));
           
                toast.success("The form was sent successfully.", {
          autoClose: 1000,
        });

                this.$router.push({ name: "MyService3" });
              })
              .finally(() => (this.loading = false))
       
              .catch((error) => {
                console.log(error.response);
                this.form.errors = error.response.data;
                console.log(this.form.errors);
                //  alert(this.form.errors.error)

                if (error.response.data.message) {
                 this.$swal.fire({
                    confirmButtonColor: "#00364f",
                  icon: "error",
                    title: "Oops...",
                   text: error.response.data.message,
                  });
               }

              //   if (error.response.data.error) {
              //     this.$swal.fire({
              //       confirmButtonColor: "#00364f",
              //       icon: "error",
              //       title: "Oops...",
              //       text: error.response.data.error,
              //     });
              //   }
              });
          }
        });
    },
    findRecordeByPanId() {
      // alert(this.panNo)
      this.loading = true;
      this.$axios
        .get(`retailer/client?pan=${this.panNo}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.record = res.data.data.data[0];
          if (res.data.data.data[0]) {
            this.form = this.record;

              this.formPanDisabled = 'true'

            console.log("------------------------------------------------------------");

            localStorage.setItem("client_id", this.record.id);
            console.log(this.record);
            console.log("------------------------------------------------------------");
            for (var i = 0; i < this.record.business_clients.length; i++) {
              this.allBusiness.push(this.record.business_clients[i].business);
            }
            this.veryfied = "false";
          } else {
            this.formPanDisabled = 'false'
            this.$swal.fire({
              icon: "error",
              title: "<h5>This Client Is Not Found Please Create First.</h5>",
              showConfirmButton: true,
            });
            // this.form.pan = this.panNo;
            this.form = {};
            this.panNo = "";
          }
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.error,
          });
        });
    },
    redirectBack() {
      var url = localStorage.getItem("back-url")


     this.$router.push(`/retailer/${url}`);
      // this.$router.push({ name: "Itr" });
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.getFaqsRecords();
      var itr = {};
      itr = JSON.parse(localStorage.getItem("service"));
      if (itr.serviceCharge > 0) {
        this.serviceCharge = itr.serviceCharge;
        this.serviceName = itr.serviceName;
        this.category = itr.categoryName;
      } else {
        this.serviceCharge = itr.serviceCharge;
        this.serviceName = itr.serviceName;
        this.category = itr.categoryName;
        this.formCharge.service_id= itr.service_id
        this.stepNo = itr.stepNo
        this.defaultApiChargeCalulate()

      }
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.accordian-plus {
  width: 20px;
  height: 20px;
  stroke: red;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  font-family: "Poppins", sans-serif;
}

.p-text {
  color: #fff;
  /* font-family: poppins, Sans-serif; */
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.bg-image {
  background-color: #fff;
  background-image: url(https://apk.mudhrape.com/wp-content/uploads/2022/01/background.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15px 0 120px;
  /* text-align: center; */
  color: white;
  /* background-size: 100% 100%;
   width: 100%; */
  font-family: "Poppins", sans-serif;
}

.h2-heading {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 767px) {
  .h2-heading {
    font-size: 22px;
    font-family: "Poppins", sans-serif;
  }
}

.heading {
  color: #00364f;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 20px 0;
}

.inner-card {
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 50%);
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px 5px 5px 5px;
  padding: 10px;
}

.heading-red {
  color: #f21300;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
}
</style>
